document.addEventListener('DOMContentLoaded', function () {
    projekteSmartphoneMarginBottom();
});

var projekteSmartphoneMarginBottom = debounce(function () {
    const letztesProjekt = document.querySelector('.projekte__projekt:last-child');
    const letztesProjektBild = letztesProjekt.querySelector('.projekte__bild');
    const letztesProjektBildHoehe = letztesProjektBild.clientHeight;
    const viewportHoehe = window.innerHeight;
    const dynamischerMargin = (50 * viewportHoehe / 100) - (letztesProjektBildHoehe / 2);
    
    if (window.matchMedia("(max-width: 767px)").matches) {
        letztesProjekt.style.marginBottom = dynamischerMargin + 'px';
    } else {
        letztesProjekt.style.marginBottom = '27.5%';
    }
}, 200);

window.addEventListener('resize', () => {
    projekteSmartphoneMarginBottom();
});

let matchMedia = gsap.matchMedia();

matchMedia.add("(max-width: 767px)", () => {
    let projekteProjekte = gsap.utils.toArray('.projekte__projekt');
    let projekteTitel = gsap.utils.toArray('.projekte__beschriftung--smartphone');

    projekteProjekte.forEach((projekteProjekt, index) => {
        let projektBeschriftung = document.querySelector('.projekte__beschriftung--smartphone');
        let projektTitel = projekteTitel[index + 1];
        let projekteProjektMargin = gsap.getProperty(projekteProjekt, "margin-bottom");
        let beruehrungspunkt = (projekteProjekt.offsetHeight - projekteProjektMargin) - projektBeschriftung.offsetHeight;


        console.log(projekteProjekt.offsetHeight, projektBeschriftung.offsetHeight, projekteProjektMargin);

        const projekteProjektTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: projekteProjekt,
                start: () => `top+=${(projekteProjekt.offsetHeight + projektBeschriftung.offsetHeight) / 2} top+=85%`,
                end: () => `top+=${projekteProjekt.offsetHeight} top`,
                scrub: true,
                markers: true,
                toggleActions: "play none none none",
            }
        })
        .to(projekteProjekt, { filter: 'blur(0px)', duration: 0.5 })
        .to(projekteProjekt, { filter: 'blur(2px)', duration: 0.5 }, 0.5);

        const projekteTitelTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: projekteProjekt,
                start: () => `top+=${beruehrungspunkt} top`,
                end: () => `top+=${projekteProjekt.offsetHeight} top`,
                scrub: true,
                toggleActions: "play none none none",
            }
        });

        gsap.set(projekteTitel, { yPercent: 100 });
        gsap.set(projekteTitel[0], { yPercent: 0 });

        if (index === 0) {
            // Spezielle Behandlung für den ersten Titel
            projekteTitelTimeline
                .to(projekteTitel[0], { yPercent: -100, duration: 0.5 })
                .to(projektTitel, { yPercent: 0, duration: 0.5 }, 0);
        } else {
            projekteTitelTimeline
                .to(projekteTitel[index], { yPercent: -100, duration: 0.5 }, 0)
                .to(projektTitel, { yPercent: 0, duration: 0.5 }, 0);
        }
    });
});
gsap.globalTimeline.clear();

// Hover-Effekt
document.querySelectorAll('.projekte__projekt').forEach(project => {
    let timeout;

    project.addEventListener('mouseenter', () => {
        clearTimeout(timeout);
        project.setAttribute('data-hover', 'true');
    });

    project.addEventListener('mouseleave', () => {

        timeout = setTimeout(() => {
            project.setAttribute('data-hover', 'false');
        }, 200); 
    });
});



